import moment from "moment";
import {
  isDev,
  lettersToNumberMap,
  loginCookieName,
  MIN_FEED_CARD_SIZE,
  numToLetterMap,
  POWERPOINT_CONSTANTS,
} from "constants/variables/index";

import Fuse from "fuse.js";
import Loader from "components/loader/index";
import { asArray } from "shared/helpers/asArray";

export function setCookie(key, value) {
  document.cookie = `${key}=${value}; Path=/; domain=${
    isDev ? "localhost" : "shelfgram.com"
  }; Secure; SameSite=None; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
}

export function loadImageDetails(imgPath) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const base64String = canvas.toDataURL("image/png");

      resolve({
        width: img.width,
        height: img.height,
        src: img.src,
        base64: base64String,
      });
    };

    img.onerror = (event) => {
      const errorMessage =
        event && event.path && event.path[0] && event.path[0].currentSrc
          ? `Error loading image from ${event.path[0].currentSrc}`
          : "Error loading image.";
      reject(new Error(errorMessage));
    };

    img.src = imgPath;
  });
}

export function getCookie(cookieName) {
  const cookie = decodeURIComponent(document.cookie).split('; ').find(row => row.startsWith(`${cookieName}=`));
  return cookie?.split('=')[1] ?? "";
}

export function destroyLoginCookieAndLocalStorage() {
  document.cookie = `${loginCookieName}=; Path=/; domain=${
    isDev ? "localhost" : "shelfgram.com"
  }; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  localStorage.clear();
}

export function randomNumberGenerator() {
  return Math.floor(Math.random() * 56);
}

export function fuzzySearch(options, query) {
  const fuse = new Fuse(options, {
    keys: ["name"],
    threshold: 0.3,
  });

  return fuse.search(query).map((x) => x.item);
}

export function scrollToTop() {
  const scroller = document.getElementById("scroller-enabled");
  if (scroller) {
    scroller.scrollTo(0, 0);
  }
}

export function numberSuffix(number) {
  const lastDigit = number.toString().split("").pop();
  if (lastDigit == "1") {
    return "st";
  } else if (lastDigit == "2") {
    return "nd";
  } else if (lastDigit == "3") {
    return "rd";
  }
  return "th";
}

export function addOpacity(rgbString, opacity) {
  if (rgbString && opacity) {
    return rgbString.split(")")[0] + "," + opacity + ")";
  }
}

function componentToHex(c) {
  let hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(rgbString) {
  if (rgbString) {
    let individualNumbers = rgbString
      .replace(")", "")
      .replace("(", "")
      .split(",");
    return (
      componentToHex(parseInt(individualNumbers[0].replace(" ", ""), 10)) +
      componentToHex(parseInt(individualNumbers[1].replace(" ", ""), 10)) +
      componentToHex(parseInt(individualNumbers[2].replace(" ", ""), 10))
    );
  } else {
    return "CCCCCC";
  }
}

export function hexToRgb(hex) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  return "rgb(" + r + "," + g + "," + b + ")";
}

export function titleCase(str) {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

export function arrayToSentence(arr) {
  let last = arr.pop();
  return arr.join(", ") + " and " + last;
}

export function mapProvider(x, y, z) {
  const s = String.fromCharCode(97 + ((x + y + z) % 3));
  return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
}

export function numToLetter(numbers) {
  let letterHash = "";
  [...numbers.toString()].forEach(function (number) {
    if (number in numToLetterMap) {
      letterHash += numToLetterMap[number];
    }
  });
  return letterHash;
}

export function letterToNumber(letters) {
  let numberHash = "";
  [...letters.toString()].forEach(function (letter) {
    if (letter in lettersToNumberMap) {
      numberHash += lettersToNumberMap[letter];
    }
  });
  return numberHash;
}

const loadingComponentStyles = {
  wrapper: {
    margin: "1em",
    border: "1px solid grey",
    borderRadius: "5px",
    overflow: "hidden",
  },
  inner: {
    padding: "1em",
    background: "rgba(255,0,0,0.1)",
    borderBottom: "1px solid grey",
  },
  strong: { padding: "1em", color: "darkslategray" },
  button: {
    cursor: "pointer",
    background: "mediumseagreen",
    color: "white",
    fontWeight: "bold",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
  },
  error: { padding: "1em", background: "whitesmoke" },
  code: { color: "darkslategray" },
};

export function LoadingComponent(props) {
  if (props.error) {
    if (props.error.message.includes("ChunkLoadError")) {
      window.location.href += "?ChunkLoadError=true";
      window.location.reload();
      return <div />;
    }
    return (
      <div style={loadingComponentStyles.wrapper}>
        <div style={loadingComponentStyles.inner}>
          <strong style={loadingComponentStyles.strong}>
            Oops, we've upgraded this section since you last visited.
          </strong>
          <button
            onClick={() => window.location.reload()}
            style={loadingComponentStyles.button}
          >
            Reload
          </button>
        </div>
        <div style={loadingComponentStyles.error}>
          <code style={loadingComponentStyles.code}>
            {props.error.name.toString().substring(0, 500)}
          </code>
          <code style={loadingComponentStyles.code}>
            {`${props.error.message.toString().substring(0, 500)}...`}
            {props.error.description}
            {props.error.number}
            {props.error.fileName}
            {props.error.stack}
          </code>
        </div>
      </div>
    );
  } else if (props.pastDelay) {
    return (
      <Loader width="auto" height="auto" size="1.5em" color="whitesmoke" />
    );
  } else {
    return null;
  }
}

export function toPastel(rgbString, discount, opacity) {
  if (rgbString) {
    rgbString = rgbString.replace(")", "").replace("(", "").split(",");
    const red = parseInt(rgbString[0], 10) + discount;
    const green = parseInt(rgbString[1], 10) + discount;
    const blue = parseInt(rgbString[2], 10) + discount;
    return `(${red > 250 ? red - discount : red},${
      green > 250 ? green - discount : green
    },${blue > 250 ? blue - discount : blue}, ${opacity})`;
  } else {
    return "(255,255,255,0.5)";
  }
}

export function toDarker(rgbString, discount, opacity) {
  if (rgbString) {
    rgbString = rgbString.replace(")", "").replace("(", "").split(",");
    const red = parseInt(rgbString[0], 10) - discount;
    const green = parseInt(rgbString[1], 10) - discount;
    const blue = parseInt(rgbString[2], 10) - discount;
    return `(${red < 0 ? 0 : red},${green < 0 ? 0 : green},${
      blue < 0 ? 0 : blue
    }, ${opacity})`;
  } else {
    return "(0,0,0,0.5)";
  }
}

export function isDarkTint(rgbString) {
  if (rgbString) {
    rgbString = rgbString
      .replace("rgba", "")
      .replace("rgb", "")
      .replace(")", "")
      .replace("(", "")
      .split(",");
    const red = parseInt(rgbString[0], 10);
    const green = parseInt(rgbString[1], 10);
    const blue = parseInt(rgbString[2], 10);
    if (red * 0.299 + green * 0.587 + blue * 0.114 > 184) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function textColor(rgbString) {
  if (rgbString.includes("#")) {
    return textColor(hexToRgb(rgbString));
  } else {
    if (isDarkTint(rgbString)) {
      return "#000000";
    } else {
      return "#ffffff";
    }
  }
}

export function numberFormatter(number) {
  let prefix = "";
  let suffix = "";
  if (number) {
    if (number >= 1000000000000) {
      suffix = "trillion";
      prefix = Math.round((number / 1000000000000) * 100) / 100;
    } else if (number >= 1000000000 && number < 1000000000000) {
      suffix = "billion";
      prefix = Math.round((number / 1000000000) * 100) / 100;
    } else if (number >= 1000000 && number < 1000000000) {
      suffix = "million";
      prefix = Math.round((number / 1000000) * 100) / 100;
    } else if (number >= 1000) {
      prefix = `${Math.round((number / 1000) * 10) / 10}K`;
    } else {
      return number;
    }
  }
  return `${prefix}${suffix ? ` ${suffix}` : ""}`;
}

export function generateRandomString() {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < 20; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function cardWidth(cardsInLayout) {
  const width = window.innerWidth || 1000;
  if (width < 425) {
    return width * 0.95;
  }
  if (cardsInLayout) {
    const cardSize = width / (cardsInLayout + 0.1);
    if (cardSize < MIN_FEED_CARD_SIZE) {
      return MIN_FEED_CARD_SIZE;
    } else {
      return cardSize;
    }
  } else {
    return width / 4.1;
  }
}

export function cardHeight(cardsInLayout, ratio) {
  const width = window.innerWidth || 1000;
  const height = window.innerHeight || 1000;
  if (width < 425) {
    if (ratio) {
      return width * 0.9 * ratio;
    }
    return width * 0.9;
  }
  if (cardsInLayout) {
    const cardSize = width / (cardsInLayout + 0.1);
    if (cardSize < MIN_FEED_CARD_SIZE) {
      if (ratio) {
        return MIN_FEED_CARD_SIZE * ratio;
      }
      return MIN_FEED_CARD_SIZE;
    } else {
      if (ratio) {
        return cardSize * ratio;
      }
      return cardSize;
    }
  } else {
    return height / 2;
  }
}

export function guessNameFromEmail(email) {
  let output = email.split("@")[0];
  output = output.split("+")[0];
  output = output
    .replace(/\d/g, "")
    .replace(/\./g, " ")
    .replace(/_/g, " ")
    .replace(/\s\s+/g, " ")
    .trim();
  output = titleCase(output)
    .replace(/Mc(.)/g, function (m, m1) {
      return "Mc" + m1.toUpperCase();
    })
    .replace(/[A-Z]\'(.)/g, function (m, m1) {
      return "O'" + m1.toUpperCase();
    })
    .split(" ");
  if (output.length === 2) {
    return output;
  } else {
    return null;
  }
}

export function checkTimeInvalidation(lastUpdated, hours) {
  return moment(new Date()).diff(moment(lastUpdated), "hours") > hours;
}

export function scrollToTopOfElement(elementId) {
  const a = document.getElementById(elementId);
  if (a) {
    a.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}

export function semverGreaterThan(versionA, versionB) {
  if (versionA && versionB) {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
  }
  return false;
}

export function sanitizeSearchQuery(inputQuery) {
  if (/^\d+$/.test(inputQuery)) {
    return inputQuery;
  }
  return inputQuery.replace(/[^a-zA-Z0-9 ']/g, "");
}

export function removeQueryParamsFromUrl() {
  const url = new URL(window.location.href);
  const newPath = url.origin + url.pathname;
  window.history.replaceState({}, "", newPath);
}

export function queryStringToObject(qs) {
  if (qs) {
    const searchStr = qs.charAt(0) === "?" ? qs.substring(1) : qs;
    return searchStr.split("&").reduce((acc, param) => {
      const [key, value] = param.split("=");
      acc[decodeURIComponent(key)] = decodeURIComponent(value);
      return acc;
    }, {});
  }
  return {};
}

export function objectToQueryString(obj) {
  return asArray(obj ?? {}).map(o =>
    Object.entries(o)
      .filter(([_, v]) => v)
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join("&")
  ).join("---");
}

export function replaceLastSlug(currentURL, newSlug) {
  const segments = currentURL.split("/");
  segments[segments.length - 1] = newSlug;
  return segments.join("/");
}

export async function powerpointtProcessImage(
  imgPath,
  slide,
  x,
  y,
  sizePercent
) {
  try {
    const imageData = await loadImageDetails(imgPath);
    let aspectRatio = imageData.width / imageData.height;
    if (aspectRatio > 2) {
      aspectRatio = 2;
    }
    slide.addImage({
      data: imageData.base64,
      x: x + 0.01 + 0.05 * sizePercent,
      y: y + 0.01 + 0.05 * sizePercent * aspectRatio,
      h: 0.4,
      w: 0.4,
      sizing: { type: "contain", h: 0.4 / aspectRatio, w: 0.4 },
    });
  } catch (error) {
    console.error("Error loading image in processImage:", error);
  }
}

export function powerpointtCalculateStartingX(numItems, averageWidth) {
  const maxItemsPerRow = numItems > 5 ? 5 : numItems;
  return (
    (9.7 -
      maxItemsPerRow * averageWidth * POWERPOINT_CONSTANTS.CARD_WIDTH_FACTOR) /
    2
  );
}
